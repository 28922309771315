import React from 'react';
import { HeroList } from '../heores/HeroList';

export const MarvelScreen = () => {
    return (
        <div>
            <h1>MarvelScreen</h1>
            <hr />
            <HeroList publisher="Marvel Comics" />
        </div>
    );
};
